<template>
  <svg viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.7606 19.0646V17.0646C17.7606 16.0037 17.3391 14.9863 16.589 14.2361C15.8388 13.486 14.8214 13.0646 13.7606 13.0646H5.76056C4.69969 13.0646 3.68228 13.486 2.93213 14.2361C2.18199 14.9863 1.76056 16.0037 1.76056 17.0646V19.0646" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.76056 9.06458C11.9697 9.06458 13.7606 7.27371 13.7606 5.06458C13.7606 2.85544 11.9697 1.06458 9.76056 1.06458C7.55142 1.06458 5.76056 2.85544 5.76056 5.06458C5.76056 7.27371 7.55142 9.06458 9.76056 9.06458Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M23.7606 19.0646V17.0646C23.7599 16.1783 23.4649 15.3174 22.9219 14.6169C22.3789 13.9164 21.6187 13.4161 20.7606 13.1946" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.7606 1.19458C17.621 1.41488 18.3836 1.91528 18.9282 2.61689C19.4728 3.3185 19.7684 4.18141 19.7684 5.06958C19.7684 5.95775 19.4728 6.82066 18.9282 7.52227C18.3836 8.22388 17.621 8.72428 16.7606 8.94458" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconUserSolid'
}
</script>
